html,body {
	-moz-osx-font-smoothing: grayscale;
}

/**
 * Header
 */
.header {
 padding: 50px 20px 40px;
 background-color: #515865;
}

.header-container {
 max-width: 700px;
 margin: 0px auto;
}

.nav {
	color: #707070;
	text-transform: uppercase;
 font-size: 16px;
 font-weight: 600;
 font-family: "Montserrat",sans-serif;
 display:inline;
}

.nav li {
 padding-right: 20px;
 display: inline-block;
 line-height: 1;
}

li {
 margin-left: 0px;
 line-height: 1.7em;
}

.nav a {
 color: #959BA6;
}

.nav a:link {
 opacity: 1;
 transition: all 0.15s linear 0s;
 text-decoration: none;
}

.nav li a:visited {
 opacity: 1;
 transition: all 0.15s linear 0s;
 text-decoration: none;
}

.nav li a:hover {
 opacity: 0.6;
 cursor: pointer;
 text-decoration: none;
}

.title li a {
 color: #FFF;
}

.title li a:link {
 color: #FFF;
}

.title li a:visited {
 opacity: 1;
 transition: all 0.15s linear 0s;
 text-decoration: none;
}

.title li a:hover {
 opacity: 1;
 color: #FFF;
}

.description {
 padding-top: 10px;
 font-size: 12px;
 line-height: 1.5em;
 color: #EEE;
}

.social-links {
 padding-top: 10px;
	margin-left: 0px;
}

.social-links li {
 display: inline;
 padding-right: 10px;
 line-height: 0;
}

.social-links a {
 color: #959BA6;
}

.social-links a:active {
	opacity: 0.6;
}

.social-links a:hover {
	opacity: 0.6;
}

.page-content {
	min-height: 100%;
	height: 100%;
}

.post-meta {
	color: #666;
	font-size: 12px;
	margin-left: 0px;
}
.content {
	margin-top: 20px;
	color: #666;
	font-size: 14px;
}

.post-list {
	list-style:none;
}

.post-list li h3 {
	margin-bottom: 5px;
	font-weight: 500px;
}

.post-list li a {
	color: #000;	
}

#line {
	opacity: 0.1;
	color: #000;
}

/**
 * Artist
 */
.well {
	color: #000;
	font-family: Monaco,"Courier New",monospace;
}

.well ul {
	color: #AAA;
	font-size: 16px;
	list-style:none;
}

.well ul a {
 text-decoration: underline;
}

.well ul a:hover {
	color: #000;
}

/**
 * Category
 */
 .category-box {
	font-weight:bold;
	font-size: 16px; 	
 }


/**
 * About
 */
.about {
	color: #666;	
}

/**
 * Footer
 */
.footer {
 font-size: 11px;
 text-align: center;
	color: #666;
}

.info, .success, .warning, .error, .validation {
	border: none;
	border-left: 4px solid;
	margin: 10px 0px;
	padding: 15px 10px 15px 50px;
	background-repeat: no-repeat;
	background-position: 10px center;
}
.info {
	color: #00529B;
	background-color: #BDE5F8;
	background-image: url('/images/icon-info.png');
}
.success {
	color: #4F8A10;
	background-color: #DFF2BF;
	background-image: url('/images/success.png');
}
.warning {
	color: #9F6000;
	background-color: #FEEFB3;
	background-image: url('/images/icon-warning.png');
}
.error{
	color: #D8000C;
	background-color: #FFBABA;
	background-image: url('/images/icon-error.png');
}
.validation{
	color: #D63301;
	background-color: #FFCCBA;
	background-image: url('/images/icon-info.png');
}

#gotop {
 position: fixed;
 right: 20px;
 bottom: 20px; 
 padding: 10px 15px; 
 font-size: 20px;
 background: #515865;
 color: white;
 cursor: pointer;
}
